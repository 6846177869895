var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('Layout',[_c('PageHeader',{attrs:{"title":_vm.title,"items":_vm.items}}),(_vm.loading)?_c('div',{staticStyle:{"text-align":"center","margin":"28px"}},[_c('pulse-loader',{attrs:{"color":"#e83e8c"}})],1):_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-body"},[_c('form-wizard',{staticClass:"tribe-form",staticStyle:{"max-width":"940px","margin":"auto"},attrs:{"color":"#556ee6","finishButtonText":"Submit"},on:{"on-complete":_vm.submitTribeForm}},[_c('tab-content',{attrs:{"icon":"mdi mdi-account-edit-outline","before-change":_vm.validateStep1}},[_c('div',{staticClass:"mb-4"},[_c('img',{staticClass:"w-100 rounded",attrs:{"src":_vm.values.cover_image,"alt":"Cover image"}})]),_c('FormulateForm',{ref:"firstStep",staticClass:"mb-4",attrs:{"name":"firstStep","schema":_vm.step1},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('div',{staticClass:"row mb-4"},[_c('div',{staticClass:"col-12"},[_c('label',[_vm._v("Cover video")]),_c('video-player',{staticClass:"video-player-box",attrs:{"options":{
                  width: '100%',
                  muted: true,
                  language: 'en',
                  playbackRates: [0.7, 1.0, 1.5, 2.0],
                  sources: [
                    {
                      type: 'video/mp4',
                      src: _vm.values.cover_video,
                    } ],
                },"playsinline":true}})],1)]),_c('label',[_vm._v("Please pick location from map")]),_c('GmapMap',{staticStyle:{"height":"240px"},attrs:{"center":_vm.center,"zoom":2},on:{"click":_vm.handleMapClick}},[_c('GmapMarker',{attrs:{"position":_vm.marker.position,"clickable":true,"draggable":true},on:{"drag":_vm.handleMarkerDrag,"click":_vm.panToMarker}})],1),_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-sm-6 col-12"},[_c('FormulateInput',{attrs:{"type":"number","name":"longitude","label":"Longitude"},model:{value:(_vm.marker.position.lng),callback:function ($$v) {_vm.$set(_vm.marker.position, "lng", $$v)},expression:"marker.position.lng"}})],1),_c('div',{staticClass:"col-sm-6 col-12"},[_c('FormulateInput',{attrs:{"type":"number","name":"latitude","label":"Latitude"},model:{value:(_vm.marker.position.lat),callback:function ($$v) {_vm.$set(_vm.marker.position, "lat", $$v)},expression:"marker.position.lat"}})],1)])],1),_c('tab-content',{attrs:{"icon":"mdi mdi-account-details-outline"}},[_c('FormulateForm',{attrs:{"schema":_vm.step2},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('FormulateForm',{staticClass:"mt-4",attrs:{"schema":_vm.transport},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}}),_c('FormulateForm',{staticClass:"mt-4 mb-4",attrs:{"schema":_vm.images},model:{value:(_vm.values),callback:function ($$v) {_vm.values=$$v},expression:"values"}})],1),_c('tab-content',{attrs:{"icon":"mdi mdi-checkbox-marked-circle-outline"}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12"},[_c('div',{staticClass:"text-center"},[_c('h6',{staticStyle:{"margin-top":"42px","margin-bottom":"84px"}},[_vm._v(" Are you sure do you want to update the tribe "),_c('strong',[_vm._v(_vm._s(_vm.values.name))]),_vm._v(" to the Tribe List? ")]),(_vm.adding)?_c('b-spinner',{attrs:{"type":"grow","label":"Spinning"}}):_vm._e()],1)])])])],1)],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }